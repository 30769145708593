import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0,2,4,5];

export const dictionary = {
		"/": [~6],
		"/api/auth": [36],
		"/(app)/(private)/collections": [~7,[2],[3]],
		"/(app)/(private)/collections/[collectionId]": [~8,[2],[3]],
		"/(app)/(private)/faqs": [~9,[2],[3]],
		"/(app)/(private)/locations/view/map": [~13,[2],[3]],
		"/(app)/(private)/locations/[country=country]": [~11,[2],[3]],
		"/(app)/(private)/locations/[slug]": [~12,[2],[3]],
		"/(app)/(private)/locations/[...rest]": [~10,[2],[3]],
		"/(app)/(private)/partners": [~14,[2],[3]],
		"/(app)/partners/pricing": [~29,[2],[3]],
		"/(app)/(private)/partners/[service_company_id]": [~15,[2],[3]],
		"/(app)/(private)/partners/[service_company_id]/chat": [~16,[2],[3]],
		"/(app)/pricing": [~30,[2],[3]],
		"/(app)/(private)/profile": [17,[2],[3]],
		"/(app)/(private)/profile/billing": [~18,[2],[3]],
		"/(app)/(private)/profile/billing/invoices/[id]": [~19,[2],[3]],
		"/(app)/(private)/profile/subscriptions": [20,[2],[3]],
		"/(app)/(private)/projects": [~21,[2],[3]],
		"/(app)/(private)/projects/[projectId]": [22,[2,4],[3]],
		"/(app)/(private)/projects/[projectId]/budgets": [~23,[2,4],[3]],
		"/(app)/(private)/projects/[projectId]/budgets/[budgetId]": [~24,[2,4],[3]],
		"/(app)/(private)/projects/[projectId]/chats": [~25,[2,4],[3]],
		"/(app)/(private)/projects/[projectId]/chats/[service_company_id]": [~26,[2,4],[3]],
		"/(app)/(private)/projects/[projectId]/locations": [27,[2,4],[3]],
		"/(auth)/reset-password": [33,[5]],
		"/(app)/(private)/service-company/[...rest]": [~28,[2],[3]],
		"/(auth)/signin": [34,[5]],
		"/(app)/signup": [~31,[2],[3]],
		"/(auth)/signup/error": [35,[5]],
		"/(app)/signup/success": [~32,[2],[3]],
		"/widgets": [~37]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';